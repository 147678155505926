import { format, formatDistance, isWithinInterval } from "date-fns"

export const formatTimestamp = (date: Date): string => {
  const oneWeekAgo = new Date()
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7)

  let formattedDate
  if (isWithinInterval(date, { start: oneWeekAgo, end: new Date() })) {
    formattedDate = formatDistance(date, new Date(), { addSuffix: true })
  } else {
    formattedDate = format(date, 'MMM d, yyyy')
  }

  return formattedDate
}

export function sendMessage(eventType: string, data?: object) {
  const message = Object.assign({}, data, {event_type: eventType})
  window.parent.postMessage(message, '*')
}