import { useAppContext } from '../contexts/app-context'
import { WebSocketEvent } from '@/lib/events'
import { useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import useWebSocket from 'react-use-websocket'
import useLocalStorage from './use-local-storage'

const useChatWebSocket = (shouldConnect: boolean, isChatOpen: boolean) => {
  const queryClient = useQueryClient()
  const urlParams = new URLSearchParams(window.location.search)
  const widgetId = urlParams.get('widget_id')

  const { setUnreadMessagesCount, setEnded } = useAppContext()
  const [isGeneratingResponse, setIsGeneratingResponse] = useState(false)
  const [lastSeenMessageId, setLastSeenMessageId] = useLocalStorage('lastSeenMessageId', null)
  const [typingTimeoutId, setTypingTimeoutId] = useState<NodeJS.Timeout | null>(null)

  const { sendJsonMessage, lastJsonMessage } = useWebSocket(
    `${process.env.PREACT_APP_WEBSOCKET_URL}?widget_id=${widgetId}`,
    {
      onOpen: () => {
        sendJsonMessage({
          command: 'subscribe',
          identifier: JSON.stringify({
            channel: 'WebchatChannel',
            widget_id: widgetId
          })
        })
      },
      onError: (event) => {
        console.error('Error WebSocket:', event)
      },
      reconnectAttempts: 10,
      reconnectInterval: (attemptNumber) => Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
      share: true,
      retryOnError: true,
      shouldReconnect: () => true
    }, shouldConnect
  )

  useEffect(() => {
    const event = lastJsonMessage as WebSocketEvent

    if (!event?.message?.event_type) return

    const message = event.message
    if (message.event_type === 'invalidate') {
      queryClient.invalidateQueries({ queryKey: ['messages'] })

    } else if (message.event_type === 'ai_typing_started') {
      setIsGeneratingResponse(true)
      setTimeout(() => {
        setIsGeneratingResponse(false)
      }, 120000) // 2 minutes

    } else if (message.event_type === 'ai_typing_ended') {
      setIsGeneratingResponse(false)

      if (!isChatOpen && message.data.sent_message_id) {
        if (lastSeenMessageId !== message.data.sent_message_id) {
          setLastSeenMessageId(message.data.sent_message_id)
          setUnreadMessagesCount((prevCount) => prevCount + 1)
        }
      }
    } else if (message.event_type === 'user_typing') {
      setIsGeneratingResponse(true)

      if (typingTimeoutId) {
        clearTimeout(typingTimeoutId)
      }

      const timeoutId = setTimeout(() => {
        setIsGeneratingResponse(false)
      }, 3000)

      setTypingTimeoutId(timeoutId)
    } else if (message.event_type === 'assigned') {
      if (message.data.assigned_to == "ended") {
        setEnded(true)
        setIsGeneratingResponse(false)
      } else {
        setEnded(false)
      }
    } else if (message.event_type === 'review_done' || message.event_type === 'review_created') {
      queryClient.invalidateQueries({ queryKey: ['contacts', 'me'] })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastJsonMessage, queryClient])

  return { isGeneratingResponse, setIsGeneratingResponse }
}

export default useChatWebSocket
